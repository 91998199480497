import { List, Map } from 'immutable';

export enum TimeUnits {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  MILLISECONDS = 'milliseconds',
}

export const MAX_NOTE_LENGTH = 1024;
export const DEFAULT_MIN_SEARCH_LENGTH = 1;
export const TIMEFRAME_DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT_DEFAULT = 'HH:mm:ss';
export const API_V3_DATETIME_FORMAT_REQUEST = 'yyyy-MM-dd hh:mm:ss';
export const API_V3_DATE_FORMAT_REQUEST = 'yyyy-MM-dd';
export const DEFAULT_COLOR = '#aaa';
export const DEFAULT_BREAK_SYMBOL = ' / ';
export const TC_ROOT_GROUP_ID = 1208;
export const BASE_HORIZONTAL_MENU_HEIGHT = 62;

export const INCOME_UPGRADE_PLAN_SQUARE_KEY = 'INCOME_UPGRADE_PLAN_SQUARE_KEY';
export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const emptyList = List();
export const emptyMap = Map();

export const REMOVE_ALERT_TIME = 10 * 1000;
export const DEFAULT_COLOR_TASK = '#F9C947';

/**
 * Angular storage keys
 */
export const DATE_PICKER_WEEK_DATE = 'ls.timesheetSelectedDate';
export const DATE_PICKER_CHOSEN_WEEK = 'setting.chosenWeek';
export const TIMESHEET_SELECTED_USER = 'ls.timesheetSelectedUser';

/**
 * Date droptown names
 */
export const DATE_DROPDOWN_NAMES = {
  THIS_WEEK: 'this_week',
  LAST_WEEK: 'last_week',
  NEXT_WEEK: 'next_week',
};

/**
 * Auth api token
 */
export const AUTH_API_TOKEN = 'api-auth-token';

export const REPORT_DONT_ASK_FOR_FEEDBACK = 'REPORT_DONT_ASK_FOR_FEEDBACK';
export const REPORT_STATISTICS_COLLAPSED = 'REPORT_STATISTICS_COLLAPSED';

/**
 * Avatars
 */
export const AVATARS_LINK = {
  GRAVATAR: 'https://www.gravatar.com/avatar/',
};

export const REACT_QUERY_GC_TIME = 1000 * 60 * 60 * 24 * 10; // 10 days

/**
 * Cache busters
 */
export const REACT_QUERY_CACHE_BUSTER = 'persist-react-query-5';
export const REDUX_CACHE_BUSTER = 'root-9';

/**
 * Tag picker
 */
export const TAG_ID_WITHOUT_TAGS = 0;

/**
 * Report Detailed Columns
 */
export const DEFAULT_COLUMN_DETAILED_SIZE = {
  min: {
    billable: 80,
    timestamp: 160,
    duration: 140,
    taskName: 210,
    note: 100,
    userName: 180,
  },
  max: {
    billable: 150,
    timestamp: 200,
    duration: 170,
    taskName: 510,
    note: 300,
    userName: 250,
  },
};
/**
 * Mobile cap overlay
 */
export const MOBILECAP_LOCALSTORAGE_KEY = 'MobileCapOverlayHide';
export const IS_EXPENSES_FEEDBACK_CLOSED = 'IS_EXPENSES_FEEDBACK_CLOSED';

/**
 * Invoices wizard
 */
export const INVOICES_MAX_NOTE_LENGTH = 1024;

export const MAX_ITEMS_IN_BADGE_PICKER = 99;

export const TASKS_LIST_COLLAPSED_STORAGE_KEY = 'TASKS_LIST_COLLAPSED';
export const RECENTLY_USED_TASKS_COLLAPSED_STORAGE_KEY = 'RECENTLY_USED_TASKS_COLLAPSED';

/**
 * Timesheet graphical graph.isSnappingEnabled
 */
export const TIMESHEET_GRAPH_SNAPPING_LS = 'graph.isSnappingEnabled';

/**
 * Breakpoint
 */
export const BREAKPOINT_MOBILE = 768;
export const BREAKPOINT_TABLET = 1024;
export const BREAKPOINT_WEB = 1280;

export const MENU_ITEM_IDS = {
  HELP: 'help',
};
